export const routes = [
    {
        path: '/task_types',
        name: 'task_types.browse',
        component: () => import(/* webpackChunkName: "TaskTypesBrowse" */ '@/views/app/TaskTypesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/task_types/create',
        name: 'task_types.create',
        component: () => import(/* webpackChunkName: "TaskTypesActions" */ '@/views/app/TaskTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/task_types/:id/edit',
        name: 'task_types.edit',
        component: () => import(/* webpackChunkName: "TaskTypesActions" */ '@/views/app/TaskTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/task_types/:id/delete',
        name: 'task_types.delete',
        component: () => import(/* webpackChunkName: "TaskTypesActions" */ '@/views/app/TaskTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]